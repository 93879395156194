.appoint_table_vie{
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #eaeef3;
    padding: 40px 0px;
  }
  
  .appoint_table_vie .back_table{
    width: 90%;
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 20px 30px 0px 20px;
    box-shadow: 0px 0px 3px rgba(32,32,32,.3);
  }
  .appoint_table_vie .back_table .table{
    /* transform: translate(-50%, 50%); */
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
    border-radius: 12px 12px 0 0;
    overflow: hidden;
    /* box-shadow: 0 12px 5px rgba(32,32,32,.3); */
    /* box-shadow: 0px 3px 3px rgba(32,32,32,.3); */
    background-color: white;
    text-align: center;
    margin-top: 10px;
  }
  .table tr th,.table tr td{
    padding: 10px 15px;
  }
  .table th{
    background-color: #00bcd4;
    color: #fafafa;
    text-transform: uppercase;
  }
  .table tr:nth-child(odd){
    background-color: #eeeeee;
  }
  .appoint_table_vie .back_table .table tr:hover{
    filter: drop-shadow(0px 2px 6px #0002);
  }

  .table tr td img{
    width: 60px;
    height: 60px;
    object-fit: cover;
    border-radius: 50%;
    border: 4px solid #fff;
    box-shadow: 0px 2px 6px #0003;
  }
  .table tr td i{
    color: blueviolet;
    font-size: 20px;
  }
  .table .status{
    padding: 10px;
    border: 1px solid blueviolet;
  }
  /* table view end */
  
  
  
  
  
  
  
  
  
  .appoint_table_vie ul{
    position: relative;
    background-color: #fff;
    display: flex;
    padding: 10px 20px;
    border-radius: 50px;
    /* box-shadow: 0 5px 15px rgba(0, 0, 0, .2); */
    box-shadow: 0 5px 15px rgba(32,32,32,.3);
    margin-top: 30px;
  }



  /* Pagination */
  .appoint_paginat{
    display: flex;
    justify-content: end;
    width: 85%;
    margin-top: 10px;
  }

  .appoint_paginat .pagination_view{
    width: 50px;
    height: 50px;
    line-height: 50px;
    text-align: center;
    margin: 0px 5px 0px 5px;
    list-style: none;
  }
  .appoint_table_vie li a{
    display: block;
    text-decoration: none;
    color: #777;
    font-weight: 600px;
    border-radius: 8px;
    border: 1px solid #00bcd4;
  }
  .appoint_table_vie li.pagination_view:hover a, .appoint_table_vie li.pagination_view.active a{
    background-color: #00bcd4;
    border: 1px solid #00bcd4;
    color: #fff;
  }
  .appoint_table_vie li:first-child{
    font-weight: 700;
    font-size: 20px;
  }
  .appoint_table_vie li:last-child{
    font-weight: 700;
    font-size: 20px;
  }
  /*  */